// main : main.scss
.topbar{
  background : #fff;

      .navbar, .navbar-bg {
        background: #212121; /* Old browsers */
        background: -moz-linear-gradient(top, #212121 0%, #3d3d3d 18%, #434343 24%, #434343 100%); /* FF3.6-15 */
        background: -webkit-linear-gradient(top, #212121 0%,#3d3d3d 18%,#434343 24%,#434343 100%); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to bottom, #212121 0%,#3d3d3d 18%,#434343 24%,#434343 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#212121', endColorstr='#434343',GradientType=0 ); /* IE6-9 */
        a.nav-link  {
          color: #fff !important;
          font-weight: 600;
          font-size: 16px;
          &.active {
            color: #999999 !important;
          }
        }
      }

      .dropdown-toggle::after {
        color: $fidler_red;
        position: relative;
        top: 2px;
        left: 3px;
      }

      #user-links {
        .fa {
          color: $fidler_red;
          font-size: 23px;
          position: relative;
          top: 2px;
        }
        a {
          color: inherit;
        }
      }

      #header-title {
        margin-left: 20px;
        text-transform: uppercase;
        font-size: 20px;
        font-weight: 700;
        top: 4px;
        position: relative;
        letter-spacing: 2px;
        .red {
          color: $fidler_red;
        }
      }
}
