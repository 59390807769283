// compileCompressed: ../css/main.css
  @import "variables";
  @import "header";
  @import "editor";
  @import "vertical-tabs";

body {
  padding-bottom: 40px;
}  

  .table{
    font-size: $font-size-sm;
    th, td{ 
      vertical-align: middle;
    }
    th.delete, th.edit, td.delete, td.edit, td.print {
      width: 40px;
    }
  }


.modal-xl {
    max-width: 95%;
}

.modal-dialog, .modal-content {
  border-radius: 0;
  border: none;
  .modal-header {
    background-color: $fidler_black;
    color: #fff;
    border-radius: 0;
    .close {
      color: #fff;
      &:hover {
        color: inherit;
      }
      &:focus {
        outline: none !important;
      }
    }
  }
}

.btn {
  border-radius: 5px;
  &.btn-success {
    background-color: $fidler_green;
    border-color: $fidler_green;
    transition: 0.5s;
    &:hover {
      background-color: #1e7e34;
    }
  }
}

.table {
  &.table-bordered {
    border: 0;
  }
  border-color: #fff;
  thead {
    th {
      background-color: $fidler_green;
      color: #fff;
      border-color: #fff;
      border-right-width: 2px;
      border-bottom-width: 0px;
      &:last-child {
        border-right:0;
      }
    }
  }
  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: #dadada;
  }
  &.table-striped tbody tr:nth-of-type(even) {
    background-color: #e9e9e9;
  }
  tbody {
    th, td {
      border-color: #fff;
      border-right-width: 2px;
      border-top-width: 2px;
      &:last-child {
        border-right:0;
      }
    }
    th {
      background-color: $fidler_black;
      color: #fff;
    }
    
  }
}

.page-item:first-child .page-link,.page-item:last-child .page-link {
  border-radius: 0;
}

.breadcrumb {
  margin-bottom: 0;
  padding-left: 0;
  background-color: transparent;
  a {
    color: inherit;
  }
  .breadcrumb-item+.breadcrumb-item::before {
    content : "\f105";
    font: normal normal normal 14px/1 FontAwesome;
  }
  li {
    font-size: 13px;
  }
  li:last-child {
    &, > * {
      font-weight: 700;
      color: $fidler_green;
    }
  }
}


.app-page {
  &.animated {
    position: absolute;
  }
  ul.page-tabs {
    li {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      a {
        border: 2px solid #ccc;
        border-radius: 5px;
        color: $fidler_green;
        transition: 0.2s;
        &.active, &:hover {
          background-color: $fidler_green;
          color: white;
          font-weight: 700;
          border: 2px solid $fidler_green;
        }
      }
    }
  }
}